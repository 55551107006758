import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { VariantType } from "notistack";
import { useAPIGateway } from "../api-gateway/APIGatewayProvider";
import { useAppConfig } from "../config/AppConfigProvider";
import log from "loglevel";

import {
  V1beta1Api as CardAPI,
  Configuration as CardAPIConfig,
  PermissionsApi as CardPermissionsAPI,
  CardSummary,
  CardSummaryStatusEnum,
  CardLogo,
  Card,
  CardRequestSummary,
  CardUpdateRequest,
  CardUpdateRequestActionEnum,
  CardUpdateResponseType,
  V1beta1CardRequestsListWorkflowStateEnum as CardRequestWorkflowFilterOptions,
  CardRequestSummaryWorkflowStateEnum,
  CardIdentifierSummarySchemeEnum,
  V1beta1CardIdentifiersListRequest,
  PaginatedCardIdentifierSummaryList,
  V1beta1CardIdentifiersRetrieveRequest,
  CardIdentifier,
  V1beta1AnalyticsGetGroupByEnum,
} from "./card";

export const CRSID_SCHEME = CardIdentifierSummarySchemeEnum.V1PersonIdentifiersCamAcUk;
export const LEGACY_SCHEME =
  CardIdentifierSummarySchemeEnum.PersonV1LegacyCardUniversityIdentifiersCamAcUk;
export const USN_SCHEME =
  CardIdentifierSummarySchemeEnum.PersonV1StudentRecordsUniversityIdentifiersCamAcUk;
export const STAFF_NUMBER_SCHEME =
  CardIdentifierSummarySchemeEnum.PersonV1HumanResourcesUniversityIdentifiersCamAcUk;
export const PHOTO_SCHEME =
  CardIdentifierSummarySchemeEnum.PhotoV1PhotoUniversityIdentifiersCamAcUk;

export type PersonIdentifier = {
  scheme: typeof CRSID_SCHEME | typeof LEGACY_SCHEME;

  value: string;
};

/**
 * The following are exported here (having possibly been renamed) to abstract their usage from
 * changes to client generation
 */

// Directly from generated client
export {
  CardRequestUpdateRequestActionEnum,
  CardRequestBulkUpdateElementRequestActionEnum,
  CardRequestWorkflowStateEnum,
  CardIdentifierSummarySchemeEnum,
  CardRequestSummaryWorkflowStateEnum,
  CardSummaryStatusEnum,
} from "./card";

export type { CardRequest, CardSummary, CardIdentifier, CardIdentifierSummary } from "./card";

// Used here as well as exported
export { CardRequestWorkflowFilterOptions };

export enum WorkflowFilterExtraOptions {
  PendingAll = "PENDING_ALL",
}

export const AllWorkflowFilterOptions = {
  ...CardRequestWorkflowFilterOptions,
  ...WorkflowFilterExtraOptions,
};
export type AllWorkflowFilterOptionsType =
  | WorkflowFilterExtraOptions
  | CardRequestWorkflowFilterOptions;

export const workflowStatusDetails = {
  [AllWorkflowFilterOptions.Pending]: {
    displayName: "Ready for Printing",
    tooltip:
      "This card request is ready and waiting to be printed.\n" +
      "Once ready, cards are usually printed on the same or next working day, except at the start of the academic year and in other exceptional circumstances.",
  },
  [AllWorkflowFilterOptions.Hold]: {
    displayName: "On Hold",
    tooltip:
      "This card request has been put on hold (typically by the Card Office).\n" +
      "See the card request 'Hold reason' for details.",
  },
  [AllWorkflowFilterOptions.Cancelled]: {
    displayName: "Cancelled",
    tooltip:
      "This card request has been cancelled.\nA card will not be created from this request.",
  },
  [AllWorkflowFilterOptions.CreatingTodo]: {
    displayName: "Printing - Sent to Printer",
    tooltip: "The card request has been queued to be picked up by a printer as soon as available.",
  },
  [AllWorkflowFilterOptions.CreatingInprogress]: {
    displayName: "Printing - Currently Printing",
    tooltip: "The card is currently being created.",
  },
  [AllWorkflowFilterOptions.CreatingInverification]: {
    displayName: "Printing - Ready for Verification",
    tooltip:
      "The card has been created but is waiting for the Card Office to quality check and verify.",
  },
  [AllWorkflowFilterOptions.CreatingDone]: {
    displayName: "Done",
    tooltip:
      "The card has been created and placed in external mail for dispatch by the University Messaging Service (UMS).\n" +
      "Once the card has reached this stage it becomes the responsibility of UMS so any complaints about delays or non-receipt should be addressed to UMS.\n" +
      "If a card has not been received within five working days of the created date, please check if the delivery information was correct before requesting a new one.",
  },
  [AllWorkflowFilterOptions.PendingCrsidRequired]: {
    displayName: "Waiting - CRISD Required",
    tooltip: "A CRSid is required before a card can be created.",
  },
  [AllWorkflowFilterOptions.PendingPhotoRequired]: {
    displayName: "Waiting - Approved Photo Required",
    tooltip:
      "A photo is required before this card can be created.\n" +
      "This status will appear if:\n" +
      "- A current photo has not been uploaded.\n" +
      "- A photo has been uploaded but is waiting for approval by the Card Office.\n" +
      "- The photo uploaded has been rejected by the Card Office (if a photo you have uploaded has been rejected you will receive an email notification).",
  },
  [AllWorkflowFilterOptions.PendingDestinationRequired]: {
    displayName: "Waiting - Delivery Destination Required",
    tooltip:
      "A delivery destination cannot be determined for this card request.\n" +
      "The delivery destination is determined from the cardholders Lookup information so the card rep should check this to identify the problem.",
  },
  [AllWorkflowFilterOptions.PendingExpiryDataRequired]: {
    displayName: "Waiting - Expiry Date Required",
    tooltip:
      "An expiry date cannot be determined for this cardholder.\n" +
      "This is calculated from the end dates provided by CamSIS, CHRIS and Lookup so the card rep should refer to these services for further details.\n" +
      "Note: there are often delays in CamSIS confirming end dates for post-graduates.",
  },
  [AllWorkflowFilterOptions.PendingAll]: {
    displayName: "Waiting - All",
    tooltip: "Includes all 'Waiting' statuses.",
  },
} as const;

export const cardStateDetails = {
  [CardSummaryStatusEnum.Issued]: {
    displayName: "Issued",
    tooltip:
      "This card has been created and is either on its way to, or has been received by, the cardholder.\n" +
      "This should be considered the cardholder's current active card.",
  },
  [CardSummaryStatusEnum.Expired]: {
    displayName: "Expired",
    tooltip:
      "This card has reached its expiry date and is no longer valid.\n" +
      "The expiry date on a card may differ from the expiry/end dates used  for the  cardholder in other systems. Details for how card expiry dates are determined can be found on the UIS help pages.",
  },
  [CardSummaryStatusEnum.Returned]: {
    displayName: "Returned",
    tooltip: "This card has been returned to the Card Office.",
  },
  [CardSummaryStatusEnum.Revoked]: {
    displayName: "Revoked",
    tooltip:
      "This card has been permanently deactivated and should no longer be considered valid.\n" +
      "A card is typically revoked if it has been lost, stolen or damaged.",
  },
  [CardSummaryStatusEnum.Unactivated]: {
    displayName: "Unactivated",
    tooltip:
      "An unactivated card can be created up to 30 days ahead of the expiry of an issued card without having to revoke the issued card first. This allows the cardholder to maintain access with their issued card until the new one has been received or the issued one has expired.\n" +
      "To activate a new card the card rep can revoke the old one, or the cardholder can wait until the old one expires - the new one then becomes the issued card. \n" +
      "It may take a day for a change of card status to filter down to all systems so the card rep may want to collect the old card back the day after issuing the new one. ",
  },
} as const;

export const orderedFiltersKeys = [
  AllWorkflowFilterOptions.Pending,
  AllWorkflowFilterOptions.CreatingTodo,
  AllWorkflowFilterOptions.CreatingInprogress,
  AllWorkflowFilterOptions.CreatingInverification,
  AllWorkflowFilterOptions.Hold,
  AllWorkflowFilterOptions.PendingAll,
  AllWorkflowFilterOptions.PendingCrsidRequired,
  AllWorkflowFilterOptions.PendingPhotoRequired,
  AllWorkflowFilterOptions.PendingDestinationRequired,
  AllWorkflowFilterOptions.PendingExpiryDataRequired,
  AllWorkflowFilterOptions.CreatingDone,
  AllWorkflowFilterOptions.Cancelled,
];

export const keyToDisplayFilterName = (key: string | undefined): string => {
  if (!key) return "All";

  const workflowKey = key as AllWorkflowFilterOptionsType;
  return workflowStatusDetails[workflowKey]?.displayName || "All";
};

export type { CardRequestSummary };

/**
 * A hook which returns an instance of the Card API using the authenticated API Gateway instance
 * and base url from the config context.
 */
export const useCardAPI = () => {
  const { fetch: apiGatewayFetch } = useAPIGateway();
  const { cardAPIBaseUrl } = useAppConfig(true);

  return new CardAPI(new CardAPIConfig({ fetchApi: apiGatewayFetch, basePath: cardAPIBaseUrl }));
};

interface UseAllCardRequestsData {
  cardRequests: CardRequestSummary[];
  cardRequestsCount: number;
}

export const useAllCardRequests = <TError = Error>(
  {
    identifier,
    cardholderStatus,
    workflowState,
    requestor,
    destination,
    createdAtGte,
    ordering,
    followNextPagination = true,
  }: {
    identifier?: string;
    cardholderStatus?: string;
    workflowState?: CardRequestWorkflowFilterOptions[];
    requestor?: string;
    destination?: string;
    createdAtGte?: string;
    ordering?: string;
    followNextPagination?: boolean;
  },
  options: Omit<
    UseQueryOptions<UseAllCardRequestsData, TError, UseAllCardRequestsData>,
    "queryFn" | "queryKey"
  > = {}
) => {
  const cardAPI = useCardAPI();

  return useQuery(
    [
      "v1beta1AllCardRequestsList",
      identifier,
      cardholderStatus,
      workflowState,
      requestor,
      destination,
      createdAtGte,
      ordering,
    ],
    async () => {
      let cursor = "";
      let cardRequests: CardRequestSummary[] = [];
      let cardRequestsCount = 0;
      do {
        const response = await cardAPI.v1beta1CardRequestsList({
          identifier,
          cardholderStatus,
          workflowState,
          requestor,
          destination,
          cursor,
          createdAtGte: createdAtGte ? new Date(Date.parse(createdAtGte)) : undefined,
          ordering,
        });
        cardRequests = cardRequests.concat(response.results || []);
        cardRequestsCount = response.count !== undefined ? response.count : 0;
        const nextParams = response.next
          ? new URLSearchParams(new URL(response.next).search)
          : undefined;
        cursor = nextParams?.get("cursor") || "";
      } while (cursor.length !== 0 && followNextPagination);

      return {
        cardRequests,
        cardRequestsCount,
      };
    },
    options
  );
};

/**
 * A hook which returns the permissions which the current user has on the Card API.
 */
export const useCardPermissions = () => {
  const { fetch: apiGatewayFetch } = useAPIGateway();
  const { cardAPIBaseUrl } = useAppConfig(true);
  const permissionsAPI = new CardPermissionsAPI(
    new CardAPIConfig({ fetchApi: apiGatewayFetch, basePath: cardAPIBaseUrl })
  );

  const { isLoading, data: permissions } = useQuery("card-permissions", async () => {
    const { permissions } = await permissionsAPI.permissionsRetrieve();
    return permissions;
  });
  return { isLoading, permissions };
};

/**
 * Utility function that sorts cards based on issue number or issued at date.
 * If the 'reverse' param is true, the sort order will be descending; otherwise, it will be ascending.
 */
export const sortCards = (cards: ReadonlyArray<CardSummary>, reverse = false) => {
  return [...cards].sort((cardA, cardB) => {
    const issueNumberDiff = (cardA.issueNumber ?? 0) - (cardB.issueNumber ?? 0);
    if (issueNumberDiff !== 0) {
      return reverse ? -issueNumberDiff : issueNumberDiff;
    }
    const issuedAtDiff = (cardA.issuedAt?.getTime() ?? 0) - (cardB.issuedAt?.getTime() ?? 0);
    return reverse ? -issuedAtDiff : issuedAtDiff;
  });
};

/**
 * Utility function which sorts cards based on issue number or issued at date
 */
export const sortRecords = (cards: ReadonlyArray<CardSummary | CardRequestSummary>) => {
  return [...cards].sort((cardA, cardB) => {
    const issueNumberDiff = (cardA.issueNumber ?? 0) - (cardB.issueNumber ?? 0);
    if (issueNumberDiff !== 0) {
      return issueNumberDiff;
    }
    return (cardA.createdAt?.getTime() ?? 0) - (cardB.createdAt?.getTime() ?? 0);
  });
};

/**
 * Utility function that sorts card requests based on the createdAt date.
 * If the 'reverse' param is true, the sort order will be descending; otherwise, it will be ascending.
 */
export const sortCardRequestsByCreatedAt = (
  cards: ReadonlyArray<CardRequestSummary>,
  reverse = false
) => {
  return [...cards].sort((cardRequestA, cardRequestB) => {
    const comparison =
      (cardRequestA.createdAt?.getTime() ?? 0) - (cardRequestB.createdAt?.getTime() ?? 0);

    return reverse ? -comparison : comparison;
  });
};

/**
 * Utility function for comparing strings.
 */

export const compareOptionalStrings = (firstItem?: string, secondItem?: string) => {
  return (firstItem ?? "-")
    .toLocaleLowerCase()
    .localeCompare((secondItem ?? "-").toLocaleLowerCase());
};

/**
 * Utility function which sorts card requests by destination, then CRSid, then created date.
 * The sort order is based on the need to generate a packing list batched by destination (one
 * packing label per destination) then CRSid to allow easier searching of a printed packing list.
 * Finally sorted by creation date, which only becomes relevant when we allow concurrent cards.
 */
export const sortCardRequests = (cards: ReadonlyArray<CardRequestSummary>) => {
  return [...cards].sort((cardRequestA, cardRequestB) => {
    // first compare destinations
    const destinationComparator = compareOptionalStrings(
      cardRequestA.destination!,
      cardRequestB.destination!
    );
    if (destinationComparator !== 0) {
      return destinationComparator;
    }
    // then compare crsids
    const CRSidA = cardRequestA.identifiers.find(
      (identifier) => identifier.scheme === CRSID_SCHEME
    )?.value;
    const CRSidB = cardRequestB.identifiers.find(
      (identifier) => identifier.scheme === CRSID_SCHEME
    )?.value;
    const crsidComparator = compareOptionalStrings(CRSidA, CRSidB);
    if (crsidComparator !== 0) {
      return crsidComparator;
    }
    // finally compare createdAt
    return (cardRequestA.createdAt?.getTime() ?? 0) - (cardRequestB.createdAt?.getTime() ?? 0);
  });
};

/**
 * Queries the Card API for cards matching a given term, returning just the cards for this
 * query.
 */
export const useCardSearch = (
  searchTerm: string,
  identifierScheme?: CardIdentifierSummarySchemeEnum,
  institution: string = "",
  queryCacheKey?: string
) => {
  const cardAPI = useCardAPI();
  const queryState = useQuery(
    ["cardSearch", queryCacheKey ?? `${searchTerm}${identifierScheme}${institution}`],
    async () => {
      if (searchTerm.length < 2 && institution === "") {
        return [];
      }
      const searchParams =
        identifierScheme && searchTerm
          ? { identifier: `${searchTerm}@${identifierScheme}` }
          : { search: searchTerm };
      const institutionParams = institution ? { institution: institution } : {};
      const fullSearch = { ...searchParams, ...institutionParams };

      const { results } = await cardAPI.v1beta1CardsList(fullSearch);
      return results;
    }
  );
  return queryState;
};

/**
 * Queries the Card API for cards of the given crsids.
 */
export const useCardsWithIdentifiers = <TError = Error, TData = CardSummary[]>(
  identifiers: string[],
  institution: string = "",
  options: Omit<
    UseQueryOptions<CardSummary[] | undefined, TError, TData>,
    "queryFn" | "queryKey"
  > = {}
) => {
  const cardAPI = useCardAPI();
  return useQuery(
    ["cardsByIdentifiers", identifiers, institution],
    async () => {
      if (identifiers.length === 0) {
        return [];
      }
      try {
        const { results } = await cardAPI.v1beta1CardsFilterCreate({
          cardFilterRequest: { identifiers, institution },
        });
        return results;
      } catch (e) {
        // If the query contains unexpected chars e.g. '@', /cards/filter returns a 400 response as the query
        // is considered invalid. Instead of letting this surface as an unhandled exception, handle as
        // an empty search result, otherwise rethrow anything else as unhandled as before
        if (e instanceof Response && e.status === 400) {
          return [];
        }
        //rethrow any other unexpected response status
        throw e;
      }
    },
    options
  );
};

/**
 * Takes the given card and the face to get the visualization for and returns the card
 * visualization data as a data uri. If a card is not provided undefined is returned.
 */
export const useCardVisualizationData = (visualizationUrl?: string) => {
  const { fetch: apiGatewayFetch } = useAPIGateway();
  const queryState = useQuery(
    ["card-visualization", visualizationUrl],
    async () => {
      if (!visualizationUrl) {
        return;
      }
      const response = await apiGatewayFetch(visualizationUrl, {
        headers: { Accept: "image/png" },
      });
      try {
        const blobData = await response.blob();

        const fileReader = new FileReader();
        return new Promise<string>((resolve) => {
          fileReader.onload = () => resolve(fileReader.result as string);
          fileReader.readAsDataURL(blobData);
        });
      } catch (e) {
        // swallow errors as not being able to render the visualization is not critical
        log.warn(`Failed to load card visualization due to ${e}`);
      }
    },
    // visualizations do not change - so it's safe to cache them infinitely
    { staleTime: Infinity, cacheTime: Infinity }
  );
  return queryState;
};

/**
 * A hook which takes an email-formatted identifier and queries a matching CRSid for a cardholder
 * or card identifier. Wraps react-query's useQuery, the CRSid being available in the "data"
 * property of the returned object along with other convenient values such as "isLoading".
 *
 * Under the covers, this makes use of useCardsWithIdentifiers and so will re-use the cached
 * results from that hook.
 */
export const useCardholderCrsId = (
  identifier: string,
  options: Omit<
    UseQueryOptions<CardSummary[] | undefined, Error, string | null>,
    "queryFn" | "queryKey"
  > = {}
) => {
  return useCardsWithIdentifiers([identifier], "", {
    ...options,

    select: (data) => {
      // Filter cards with associated CRSids and map them to a list of CRSids.
      const crsIds = (data ?? [])
        .flatMap(({ identifiers }) => identifiers)
        .filter(
          ({ scheme }) => scheme === CardIdentifierSummarySchemeEnum.V1PersonIdentifiersCamAcUk
        )
        .map(({ value }) => value.toLowerCase());

      // Return the first matching crsid or null if there is none.
      return crsIds[0] ?? null;
    },
  });
};

export const useCard = <TError = Error, TData = Card>(
  id: string,
  options: Omit<UseQueryOptions<Card, TError, TData>, "queryFn" | "queryKey"> = {}
) => {
  const cardAPI = useCardAPI();
  return useQuery(
    ["v1beta1CardsRead", id],
    async () => await cardAPI.v1beta1CardsRetrieve({ id }),
    options
  );
};

export const useCardLogo = <TError = Error, TData = CardLogo>(
  id: string,
  options: Omit<UseQueryOptions<CardLogo, TError, TData>, "queryFn" | "queryKey"> = {}
) => {
  const cardAPI = useCardAPI();
  return useQuery(
    ["v1beta1CardLogosRead", id],
    async () => await cardAPI.v1beta1CardLogosRetrieve({ id }),
    options
  );
};

interface UseCardRequestsData {
  cardRequests: CardRequestSummary[];
  nextCursor: string | null;
  previousCursor: string | null;
  cardRequestsCount: number | null;
}

export const useCardRequests = <TError = Error>(
  {
    identifier,
    cardholderStatus,
    workflowState,
    requestor,
    destination,
    cursor,
    pageSize,
    ordering,
  }: {
    identifier?: string;
    cardholderStatus?: string;
    workflowState?: CardRequestWorkflowFilterOptions[];
    requestor?: string;
    destination?: string;
    cursor?: string;
    pageSize?: number;
    ordering?: string;
  },
  options: Omit<
    UseQueryOptions<UseCardRequestsData, TError, UseCardRequestsData>,
    "queryFn" | "queryKey"
  > = {}
) => {
  const cardAPI = useCardAPI();

  return useQuery(
    [
      "v1beta1CardRequestsList",
      identifier,
      cardholderStatus,
      workflowState,
      requestor,
      destination,
      cursor,
      pageSize,
      ordering,
    ],
    async () => {
      const response = await cardAPI.v1beta1CardRequestsList({
        identifier,
        cardholderStatus,
        workflowState,
        requestor,
        destination,
        cursor,
        pageSize,
        ordering,
      });

      const nextParams = response.next
        ? new URLSearchParams(new URL(response.next).search)
        : undefined;
      const previousParams = response.previous
        ? new URLSearchParams(new URL(response.previous).search)
        : undefined;

      return {
        cardRequests: response.results || [],
        nextCursor: nextParams ? nextParams.get("cursor") : "",
        previousCursor: previousParams ? previousParams.get("cursor") : "",
        cardRequestsCount: response.count ? response.count : 0,
      };
    },
    options
  );
};

export const useCardRequestsDestinations = () => {
  const cardAPI = useCardAPI();

  return useQuery(
    ["v1beta1CardRequestsDestinations"],
    async () => {
      const response = await cardAPI.v1beta1CardRequestsDestinationsRetrieve();
      return (response.results?.sort() || []).filter(Boolean);
    },
    { staleTime: Infinity, cacheTime: Infinity }
  );
};

export const useCardRequestsCardholderStatus = () => {
  const cardAPI = useCardAPI();

  return useQuery(
    ["v1beta1CardRequestsCardholderStatuses"],
    async () => {
      const response = await cardAPI.v1beta1CardRequestsCardholderStatusesRetrieve();
      return (response.results?.sort() || []).filter(Boolean);
    },
    { staleTime: Infinity, cacheTime: Infinity }
  );
};

export const useCardRequestsRequestors = () => {
  const cardAPI = useCardAPI();

  return useQuery(
    ["v1beta1CardRequestsRequestors"],
    async () => {
      const response = await cardAPI.v1beta1CardRequestsRequestorsRetrieve();
      const requestors = [
        ...new Set(
          response.results?.map((element) =>
            element.endsWith(CRSID_SCHEME) ? CRSID_SCHEME : element
          )
        ),
      ];
      return (requestors?.sort() || []).filter(Boolean);
    },
    { staleTime: Infinity, cacheTime: Infinity }
  );
};

export { CardAPI };

export const useCardRequestDetails = (cardRequestId: string) => {
  const cardAPI = useCardAPI();
  return useQuery(
    ["v1beta1CardRequestDetail", cardRequestId],
    () => cardAPI.v1beta1CardRequestsRetrieve({ id: cardRequestId }),
    { staleTime: 0, cacheTime: 0 }
  );
};

export const useCardOperations = (opts: {
  id?: string;
  onResult?: (msg: string, severity: VariantType) => void;
}) => {
  const queryClient = useQueryClient();
  const cardAPI = useCardAPI();

  const id = opts.id;
  const onResult = opts.onResult;

  // Mutation function that marks a card as cancelled.
  const cancelCardMutation = useMutation<
    CardUpdateResponseType,
    Response,
    { cancelReason?: string }
  >(
    (opts: { cancelReason?: string }) => {
      const data: CardUpdateRequest = {
        action: CardUpdateRequestActionEnum.Cancel,
      };
      if (opts.cancelReason) {
        data.note = opts.cancelReason;
      }
      return cardAPI.v1beta1CardsUpdate({
        id: id || "",
        cardUpdateRequest: data,
      });
    },
    {
      onSuccess: () => {
        if (id) {
          queryClient.invalidateQueries(["v1beta1CardsRead", id]);
          queryClient.invalidateQueries(["v1beta1CardLogosRead", id]);
          queryClient.invalidateQueries(["cardSearch"]);
          queryClient.invalidateQueries(["cardsByIdentifiers"]);
          queryClient.invalidateQueries(["v1beta1CardIdentifiersList"]);
          queryClient.invalidateQueries(["v1beta1CardIdentifiersDetail"]);
        }
      },
      onError: (error: Response) => {
        if ([401, 403].includes(error.status)) {
          if (onResult) {
            onResult("You do not have permission to cancel cards.", "error");
          }
        } else {
          if (onResult) {
            onResult("An error occurred. Please try again later.", "warning");
          }
        }
      },
    }
  );

  return {
    cancelCardMutation,
  };
};

export interface CardRequestFilterParams {
  identifier?: string;
  cardholderStatus?: string;
  workflowState?: any;
  displayWorkflowState?: any;
  requestor?: string;
  destination?: string;
}

export const cardRequestRefreshableWorkflowStates = [
  CardRequestSummaryWorkflowStateEnum.Hold,
  CardRequestSummaryWorkflowStateEnum.Pending,
  CardRequestSummaryWorkflowStateEnum.PendingCrsidRequired,
  CardRequestSummaryWorkflowStateEnum.PendingDestinationRequired,
  CardRequestSummaryWorkflowStateEnum.PendingPhotoRequired,
];
export const useCollegeInstitutionIds = () => {
  const cardAPI = useCardAPI();
  return useQuery(
    ["v1beta1CollegeInstitutionIdsList"],
    async () => {
      const response: any = await cardAPI.v1beta1CollegeInstitutionIdsList();
      const collegeInstitutionIds: string[] = (response.results || []).filter(Boolean);
      return collegeInstitutionIds;
    },
    { staleTime: Infinity, cacheTime: Infinity }
  );
};

export const useCardIdentifiersList = (
  request: V1beta1CardIdentifiersListRequest,
  options?: Omit<UseQueryOptions<PaginatedCardIdentifierSummaryList>, "queryFn" | "queryKey">
) => {
  const cardAPI = useCardAPI();
  const queryState = useQuery(
    ["v1beta1CardIdentifiersList", request],
    () => cardAPI.v1beta1CardIdentifiersList(request),
    options
  );
  return queryState;
};

export const useCardIdentifierDetail = (
  request: V1beta1CardIdentifiersRetrieveRequest,
  options?: Omit<UseQueryOptions<CardIdentifier>, "queryFn" | "queryKey">
) => {
  const cardAPI = useCardAPI();
  return useQuery(
    ["v1beta1CardIdentifierDetail", request],
    () => cardAPI.v1beta1CardIdentifiersRetrieve(request),
    options
  );
};

export const useCardFirstCardIdentifierDetailsIfExists = (identifier?: string) => {
  const cardIdentifiersResult = useCardIdentifiersList(
    { identifier: identifier || "" },
    { enabled: !!identifier }
  );

  const cardIdentifiers = cardIdentifiersResult.data?.results || [];
  const firstIdentifier = cardIdentifiers.length ? cardIdentifiers[0].id : null;

  const cardDetailsResult = useCardIdentifierDetail(
    { id: firstIdentifier ?? "" },
    { enabled: !!firstIdentifier }
  );

  if (!firstIdentifier || firstIdentifier.trim() === "") {
    // Return an object mimicking the structure of a useQuery result with null data
    return {
      ...cardDetailsResult,
      data: null,
      isError: false,
      isLoading: false,
      isSuccess: true,
    };
  }
  // Return the regular hook's result if a valid identifier exists
  return cardDetailsResult;
};

export enum GroupByEnum {
  DAY = "day",
  MONTH = "month",
  WEEK = "week",
  YEAR = "year",
}

interface MetricsListRequest {
  groupBy: GroupByEnum;
}

export enum RevokedReasonsEnum {
  LostByCardholder = "LostByCardholder",
  LostInTransit = "LostInTransit",
  Stolen = "Stolen",
  NotWorkingPhysicalDamage = "NotWorkingPhysicalDamage",
  NotWorkingElectronicIssue = "NotWorkingElectronicIssue",
  PrintFaded = "PrintFaded",
  ChangeOfRole = "ChangeOfRole",
  ChangeOfDetails = "ChangeOfDetails",
  IncorrectDetails = "IncorrectDetails",
  NoLongerRequiresaCard = "NoLongerRequiresaCard",
  Expiring = "Expiring",
  Other = "Other",
}

type RevokedNotesEntry = {
  date: string;
} & {
  [key in RevokedReasonsEnum]?: number;
};

type AnalyticsData = {
  results: {
    issuedAt: Array<{
      date: string;
      requestorDomain?: string;
      count: number;
    }>;
    revokedAt: Array<{
      date: string;
      count: number;
    }>;
    revokedAtNotes: Array<RevokedNotesEntry>;
  };
};

export const useAnalyticsMetrics = (
  request: MetricsListRequest,
  options?: Omit<UseQueryOptions<AnalyticsData>, "queryFn" | "queryKey">
) => {
  const cardAPI = useCardAPI();
  return useQuery(["v1beta1AnalyticsMetrics", request.groupBy], async () => {
    const response = await cardAPI.v1beta1AnalyticsGet({
      ...request,
      groupBy: request.groupBy as unknown as V1beta1AnalyticsGetGroupByEnum,
    });

    const revokedNotes = response.results.revokedAtNotes || [];

    const camelCaseToPascalCase = (str: string): string =>
      str.charAt(0).toUpperCase() + str.slice(1);

    const cleanedRevokedAtNotes = revokedNotes.map((note) => {
      const cleanNote: Partial<RevokedNotesEntry> = { date: new Date(note.date).toISOString() };

      for (const key in note) {
        if (
          note.hasOwnProperty(key) &&
          note[key as keyof typeof note] !== undefined &&
          key !== "date"
        ) {
          const transformedKey = camelCaseToPascalCase(key);
          cleanNote[transformedKey as keyof RevokedNotesEntry] = note[
            key as keyof typeof note
          ] as any;
        }
      }

      return cleanNote as RevokedNotesEntry;
    });
    return {
      ...response.results,
      revokedAtNotes: cleanedRevokedAtNotes,
    };
  });
};
